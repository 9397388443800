import App from 'next/app';
import Router from 'next/router';
import withGA from 'next-ga';
import { ApolloProvider } from '@apollo/client';
import compose from 'lodash/flowRight';
import withApolloClient from '../utils/with-apollo-client';
import Layout from '../components/layout';
import config from '../config';
import sentry from '../utils/sentry';
import ErrorPage from '../components/error-page';
import 'nprogress/nprogress.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'react-datetime/css/react-datetime.css';
import '../components/event-calendar-raw.css';

const { Sentry, captureException } = sentry();

class EverydayRides extends App {
  static async getInitialProps({ Component, ctx }) {
    try {
      let pageProps = {};
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }

      return {
        pageProps,
      };
    } catch (error) {
      console.log(error);
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx);
      return {
        hasError: true,
        errorEventId,
      };
    }
  }

  static getDerivedStateFromProps (props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError () {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorEventId: undefined,
    };
  }

  // // deprecated in next 11
  // componentDidCatch (error, errorInfo) {
  //   const errorEventId = captureException(error, { errorInfo });
  //   // Store the event id at this point as we don't have access to it within
  //   // `getDerivedStateFromError`.
  //   this.setState({ errorEventId });
  // }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  render() {
    const { Component, pageProps, apolloClient } = this.props;
    const { hasError, errorEventId } = this.state;

    return (
      <ApolloProvider client={apolloClient}>
        <Layout Sentry={Sentry}>
          {hasError ? (
            <ErrorPage Sentry={Sentry} errorEventId={errorEventId} />
          ) : (
            <Component {...pageProps} />
          )}
        </Layout>
      </ApolloProvider>
    );
  }
}

export default compose(
  withGA(config().client.googleAnalyticsTrackingId, Router),
  withApolloClient
)(EverydayRides);
