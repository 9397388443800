import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import _get from 'lodash/get';
import moment from 'moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import { useQuery } from '@apollo/client';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import Header from '../components/header';
import MobileDrawer from '../components/mobile-drawer';
import theme from '../theme';
// import { getUserLocation } from '../utils/location';
import { login, logout } from '../utils/auth';
import { meQuery, nearestCityQuery } from '../apollo/auth';
import AppContext from '../contexts/app';
// import hubspot from '../utils/hubspot';

let progressTimer;

const Layout = (props) => {
  const { children, Sentry } = props;
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  // const [userLocation, setUserLocation] = useState(null);
  // const [userLocationLoading, setUserLocationLoading] = useState(false);
  const router = useRouter();

  const { data: meData, loading: authLoading } = useQuery(meQuery);
  const { me: loggedInUser } = meData || {};
  const isLoggedIn = Boolean(loggedInUser);

  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    Router.events.on('routeChangeStart', () => {
      progressTimer = setTimeout(NProgress.start, 300);
    });

    Router.events.on('routeChangeComplete', () => {
      if (progressTimer) {
        clearTimeout(progressTimer);
      }
      NProgress.done();
      window.scrollTo(0, 0);
    });

    Router.events.on('routeChangeError', () => {
      if (progressTimer) {
        clearTimeout(progressTimer);
      }
      NProgress.done();
    });

    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: loggedInUser.id,
          username: loggedInUser.username,
          email: loggedInUser.email,
        });
      });

      // hubspot.login(loggedInUser);
    }
  }, [loggedInUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.asPath]);

  // useEffect(() => {
  //   if (!isLoggedIn && !authLoading && !userLocationLoading) {
  //     setUserLocationLoading(true);
  //     getUserLocation().then((userLocation) => {
  //       setUserLocation(userLocation);
  //     });
  //   }
  // }, [isLoggedIn, authLoading, userLocationLoading]);

  const {
    data: nearestCityData,
    // refetch: refetchNearestCity,
  } = useQuery(nearestCityQuery, {
    // variables: userLocation && userLocation.coords ? ({
    //   coordinates: {
    //     latitude: userLocation.coords.latitude,
    //     longitude: userLocation.coords.longitude
    //   }
    // }) : {}
  });
  // useEffect(() => {
  //   refetchNearestCity(userLocation && userLocation.coords ? ({
  //     coordinates: {
  //       latitude: userLocation.coords.latitude,
  //       longitude: userLocation.coords.longitude
  //     }
  //   }) : {});
  // }, [userLocation]);
  const { nearestCity } = nearestCityData || {};

  function handleLogin(returnTo) {
    login(returnTo); // login(returnTo, userLocation)
  }

  function handleLogout(returnTo) {
    logout(returnTo);
  }

  const appContext = {
    loggedInUser,
    authLoading,
    isLoggedIn,
    mobileDrawerOpen,
    // userLocation,
    // userLocationLoading,
    login: handleLogin,
    logout: handleLogout,
    setMobileDrawerOpen,
    nearestCity,
    city: _get(loggedInUser, 'primaryCity') || nearestCity,
  };

  return (
    <Fragment>
      <Head>
        <title>Everyday Rides</title>
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      <AppContext.Provider value={appContext}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Hidden mdUp implementation="css">
            <MobileDrawer />
          </Hidden>
          <Header />
          {children}
        </ThemeProvider>
      </AppContext.Provider>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  Sentry: PropTypes.object,
};

export default Layout;
