import PropTypes from 'prop-types';
import Link from 'next/link';
import Button from '@material-ui/core/Button';

const MaterialLinkButton = ({ href, as, children, ...props }) => (
  <Link href={href} as={as} legacyBehavior>
    <Button href={as || href} {...props}>
      {children}
    </Button>
  </Link>
);

MaterialLinkButton.propTypes = {
  href: PropTypes.string,
  as: PropTypes.string,
  children: PropTypes.any
};

export default MaterialLinkButton;
