import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      light: '#73a777', // #66ba6f',
      main: '#509156',
      dark: '#38653c', // #3c6e41'
      background: '#d6ead7',
    },
    secondary: {
      light: '#fff9c4',
      main: '#e8d73c',
      dark: '#d1c23d',
      background: '#fffbe4',
      darkBackground: '#a1920d',
    },
    tertiary: {
      main: '#9F7E69', // brown
      light: '#eFcEb9',
      dark: '#7F5E49',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f3f3f3',
    },
    ride: {
      main: '#2E86AB', // Blue NCS
    },
    event: {
      main: '#BB8FA9', // Opera Mauve
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 24,
      fontWeight: 700,
      marginTop: 24,
      marginBottom: 24,
    },
    h2: {
      borderBottom: '1px solid #999',
      color: '#222',
      fontSize: 20,
      fontWeight: 700,
      paddingBottom: 8,
      marginTop: 24,
      marginBottom: 16,
    },
    h3: {
      fontSize: 18,
      marginTop: 16,
      marginBottom: 8,
      fontWeight: 700,
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
      margin: 0,
      fontWeight: 700,
    },
  },
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: '0.875rem',
      },
    },
  },
});

export default theme;
