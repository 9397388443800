import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from '../contexts/app';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import MaterialLinkButton from './material-link-button';
import { useCallback } from 'react';
import MaterialLink from './material-link';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 0, 0.5, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 1.5, 0.5, 1.5),
      backgroundColor: '#fff',
    },
  },
  paper: {
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  message: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  button: {
    whiteSpace: 'nowrap',
  },
}));

const SignupBanner = () => {
  const classes = useStyles();
  const { isLoggedIn, login } = useAppContext();

  const handleLogin = useCallback((e) => {
    e.preventDefault();
    login();
  }, []);

  if (isLoggedIn) {
    return null;
  }

  return (
    <Container maxWidth="md" component="div" className={classes.container}>
      <Paper className={classes.paper}>
        <div className={classes.message}>
          <strong>New to Everyday Rides?</strong>
          {' '}Sign up to get weekly emails about upcoming events, join groups, and{' '}
          <MaterialLink href="/features">
            much more
          </MaterialLink>
          !
        </div>
        <div className={classes.buttonWrapper}>
          <MaterialLinkButton
            href="/login"
            variant="contained"
            color="primary"
            onClick={handleLogin}
            className={classes.button}
          >
            Sign Up
          </MaterialLinkButton>
        </div>
      </Paper>
    </Container>
  );
};

export default SignupBanner;
