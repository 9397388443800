export const eventAttributes = `
  id
  name
  summary
  hostType
  eventType
  visibility
  startAt
  startAtDetails
  endAt
  startLocation
  startLocationAddress
  startLocationDetails
  infoUrl
  timezone
  public
  status
  categoryIds
  loop
  distance
  pace
  terrain
  regroup
  weatherCancellation
  ageRestriction
  audienceRestriction
  registrationType
  group {
    id
    slug
    name
    image {
      filename
    }
    verified
    public
  }
  city {
    id
    slug
    name
  }
  user {
    id
    username
    displayName
    pronouns
    avatar {
      small {
        url
      }
    }
  }
  membership {
    id
    status
  }
  image {
    filename
  }
  series {
    id
  }
`;

export const memberSummaryAttributes = `
  memberSummary {
    GOING {
      count
    }
    INTERESTED {
      count
    }
  }
`;

export const eventEditableAttributes = `
  name
  description
  hostType
  eventType
  summary
  startAt
  startAtDetails
  endAt
  timezone
  public
  visibility
  registrationType
  status
  startLocation
  startLocationAddress
  startLocationDetails
  endLocation
  endLocationAddress
  endLocationDetails
  loop
  distance
  pace
  regroup
  terrain
  weatherCancellation
  infoUrl
  routeUrl
  ageRestriction
  audienceRestriction
  categoryIds
  permissions {
    editable
    accessible
    joinable
    attendeesViewable
    approved
  }
  image {
    filename
  }
  city {
    id
    slug
    name
  }
  group {
    id
    slug
    name
    permissions {
      editable
      createEventWithoutApproval
    }
  }
  user {
    id
    username
    displayName
    pronouns
    avatar {
      small {
        url
      }
    }
  }
  approvalPrompt
  approvalDescription
  contactName
  contactPhoneNumber
  contactPhoneNumberDisplay: contactPhoneNumber(format: NATIONAL)
  contactPhoneNumberUri: contactPhoneNumber(format: RFC3966)
  contactEmail
`;

export const eventListingAttributes = `
  id
  ${eventAttributes}
  ${memberSummaryAttributes}
  groupFeatured
  commentCount
  imageUploadsCount
  permissions {
    joinable
    editable
    approved
  }
`;
