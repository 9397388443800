import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import MLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Main from './main';

const ErrorPage = ({ errorEventId, Sentry }) => (
  <Main>
    <Typography variant="h1">There was an error!</Typography>
    <p>
      <Button
        variant="outlined"
        onClick={() => {
          Sentry.showReportDialog({ eventId: errorEventId });
        }}
      >
        📣 Report this error
      </Button>
    </p>
    <p>
      Or, try
      {' '}
      <MLink
        href='#'
        onClick={() => {
          window.location.reload(true);
        }}
      >
        reloading the page
      </MLink>
    </p>
  </Main>
);

ErrorPage.propTypes = {
  Sentry: PropTypes.object,
  errorEventId: PropTypes.string
};

export default ErrorPage;
