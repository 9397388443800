import gql from 'graphql-tag';
import { eventListingAttributes } from './types/event';
import { groupBasicAttributes } from './types/group';
import { groupMemberAttributes } from './types/group-member';
import { imageUploadAttributes } from './types/image-uploads';

const inviteAttributes = `
  id
  inviteType
  email
  createdAt
  expiresAt
  memberCount
  groupMember {
    id
    user {
      id
      username
      displayName
    }
  }
  createdByUser {
    id
    username
    displayName
  }
`;

const postAttributes = `
  subject
  body
  commentCount
  user {
    id
    username
    displayName
    avatar {
      small {
        url
      }
    }
  }
  createdAt
`;

const postCommentAttributes = `
  body
  createdAt
  user {
    id
    displayName
    username
    avatar {
      small {
        url
      }
    }
  }
`;

const groupPageAttributes = `
  id
  ${groupBasicAttributes}
  discoverable
  recentPostsCount
  upcomingEventsCount
  pendingUpcomingEventsCount
  city {
    id
    slug
    name
    timezone
  }
`;

export const groupQuery = gql`
  query GroupQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      description
      summary
      memberDescription
      requestPrompt
      websiteUrl
      instagramUsername
      pendingMembersCount
      membership {
        id
        role
        eventNotificationPreference
      }
      featuredUpcomingEvents: upcomingEvents(featured: true) {
        ${eventListingAttributes}
      }
      soonUpcomingEvents: upcomingEvents(soon: true) {
        ${eventListingAttributes}
      }
      pastEventsCount
      latestPost: posts(limit: 1) {
        id
        ${postAttributes}
      }
      postsCount
      memberSummary {
        OWNER {
          count
        }
        MEMBER {
          count
        }
      }
      imageUploads(limit: 12) {
        ${imageUploadAttributes}
      }
    }
  }
`;

export const groupCalendarQuery = gql`
  query GroupCalendarQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      membership {
        id
        role
        eventNotificationPreference
      }
      upcomingEvents {
        ${eventListingAttributes}
      }
      pastEventsCount
    }
  }
`;

export const groupCalendarGridQuery = gql`
  query GroupCalendarQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      membership {
        id
        role
        eventNotificationPreference
      }
      city {
        id
        timezone
      }
    }
  }
`;

export const groupInvitesQuery = gql`
  query GroupInvitesQuery($slug: String!) {
    group(slug: $slug) {
      id
      ${groupBasicAttributes}
      linkInvites: invites(type: LINK) {
        ${inviteAttributes}
      }
      emailInvites: invites(type: EMAIL) {
        ${inviteAttributes}
      }
    }
  }
`;

export const groupPastEventsQuery = gql`
  query GroupPastEventsQuery($slug: String!, $limit: Int) {
    group(slug: $slug) {
      ${groupPageAttributes}
      membership {
        id
        role
        eventNotificationPreference
      }
      pastEvents(limit: $limit) {
        ${eventListingAttributes}
      }
    }
  }
`;

export const groupCalendarEventsQuery = gql`
  query GroupCalendarEventsQuery($slug: String!, $start: DateTime!, $end: DateTime!) {
    group(slug: $slug) {
      id
      calendarEvents(start: $start, end: $end) {
        ${eventListingAttributes}
      }
    }
  }
`;

export const groupSubscribeQuery = gql`
  query GroupSubscribeQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      membership {
        id
        role
        eventNotificationPreference
        calendarToken
      }
      city {
        id
        slug
        name
      }
    }
  }
`;


export const groupMembersQuery = gql`
  query GroupMembersQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      memberSummary {
        OWNER {
          count
        }
        MEMBER {
          count
        }
      }
      membership {
        id
        role
      }
      members {
        id
        role
        createdAt
        approvedAt
        approvedByUser {
          id
          username
          displayName
        }
        invite {
          id
          inviteType
        }
        user {
          id
          username
          displayName
          email
          phoneNumberDisplay: phoneNumber(format: NATIONAL)
          phoneNumberUri: phoneNumber(format: RFC3966)
          avatar {
            small {
              url
            }
          }
        }
        emailVisible
        phoneNumberVisible
        requestMessage
      }
    }
  }
`;

export const groupPostsQuery = gql`
  query GroupPostsQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      posts {
        id
        ${postAttributes}
      }
      membership {
        id
        role
        postNotificationPreference
      }
    }
  }
`;

export const groupGalleryQuery = gql`
  query GroupGalleryQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      imageUploads {
        ${imageUploadAttributes}
      }
      membership {
        id
        role
        postNotificationPreference
      }
    }
  }
`;

export const createGroupMutation = gql`
  mutation CreateGroupMutation($data: GroupInput!) {
    createGroup(data: $data) {
      id
      ${groupBasicAttributes}
      summary
      membership {
        ${groupMemberAttributes}
      }
    }
  }
`;

const editGroupAttributes = `
  slug
  name
  description
  memberDescription
  public
  discoverable
  websiteUrl
  instagramUsername
  createEventPermission
  createPostPermission
  uploadImagePermission
  image {
    filename
  }
  requestPrompt
`;

export const editGroupQuery = gql`
  query EditGroupQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      ${editGroupAttributes}
      permissions {
        editable
      }
    }
  }
`;

export const updateGroupMutation = gql`
  mutation UpdateGroupMutation($slug: String!, $data: GroupInput!) {
    updateGroup(slug: $slug, data: $data) {
      id
      ${editGroupAttributes}
    }
  }
`;

export const joinGroupMutation = gql`
  mutation JoinGroupMutation($id: ID!, $token: String) {
    joinGroup(id: $id, token: $token) {
      ${groupMemberAttributes}
    }
  }
`;

export const requestJoinGroupMutation = gql`
  mutation RequestJoinGroupMutation($id: ID!, $message: String, $membership: GroupMemberInput) {
    requestJoinGroup(id: $id, message: $message, membership: $membership) {
      ${groupMemberAttributes}
    }
  }
`;

export const leaveGroupMutation = gql`
  mutation LeaveGroupMutation($id: ID!) {
    leaveGroup(id: $id) {
      id
    }
  }
`;

export const createGroupLinkInviteMutation = gql`
  mutation CreateGroupLinkInviteMutation($groupId: ID!) {
    createGroupLinkInvite(groupId: $groupId) {
      ${inviteAttributes}
    }
  }
`;

export const createGroupEmailInvitesMutation = gql`
  mutation CreateGroupEmailInvitesMutation($groupId: ID!, $emails: [String]!) {
    createGroupEmailInvites(groupId: $groupId, emails: $emails) {
      ${inviteAttributes}
    }
  }
`;

export const expireGroupInviteMutation = gql`
  mutation ExpireGroupInviteMutation($id: ID!) {
    expireGroupInvite(id: $id) {
      ${inviteAttributes}
    }
  }
`;

export const extendGroupInviteMutation = gql`
  mutation ExtendGroupInviteMutation($id: ID!) {
    extendGroupInvite(id: $id) {
      ${inviteAttributes}
    }
  }
`;

export const resendGroupEmailInviteMutation = gql`
  mutation ResendGroupEmailInviteMutation($id: ID!) {
    resendGroupEmailInvite(id: $id) {
      ${inviteAttributes}
    }
  }
`;

export const groupInviteQuery = gql`
  query GroupInviteQuery($id: ID!) {
    groupInvite(id: $id) {
      id
      group {
        id
        name
        slug
      }
    }
  }
`;

export const updateGroupMemberRoleMutation = gql`
  mutation UpdateGroupMemberRoleMutation($id: ID!, $role: GroupMemberRole!) {
    updateGroupMemberRole(id: $id, role: $role) {
      id
      role
    }
  }
`;

export const removeGroupMemberMutation = gql`
  mutation RemoveGroupMemberMutation($id: ID!) {
    removeGroupMember(id: $id) {
      id
    }
  }
`;

export const updateGroupMemberEventNotificationPreferenceMutation = gql`
  mutation UpdateGroupMemberEventNotificationPreferenceMutation(
    $id: ID!,
    $preference: NotificationPreference!
  ) {
    updateGroupMemberEventNotificationPreference(id: $id, preference: $preference) {
      id
      eventNotificationPreference
    }
  }
`;

export const updateGroupMemberPostNotificationPreferenceMutation = gql`
  mutation UpdateGroupMemberPostNotificationPreferenceMutation(
    $id: ID!,
    $preference: NotificationPreference!
  ) {
    updateGroupMemberPostNotificationPreference(id: $id, preference: $preference) {
      id
      postNotificationPreference
    }
  }
`;

export const newGroupPostGroupQuery = gql`
  query NewGroupPostGroupQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
    }
  }
`;

export const createGroupPostMutation = gql`
  mutation CreateGroupPostMutation($groupId: ID!, $data: GroupPostInput!) {
    createGroupPost(groupId: $groupId, data: $data) {
      id
      ${postAttributes}
    }
  }
`;

export const removeGroupPostMutation = gql`
  mutation RemoveGroupPostMutation($id: ID!) {
    removeGroupPost(id: $id) {
      id
    }
  }
`;

export const groupPostQuery = gql`
  query GroupPostQuery($id: ID!) {
    groupPost(id: $id) {
      id
      ${postAttributes}
      membership {
        id
        notificationPreference
      }
      permissions {
        editable
      }
      group {
        ${groupPageAttributes}
        membership {
          id
          role
          postNotificationPreference
        }
      }
    }
  }
`;

export const groupPostCommentsQuery = gql`
  query GroupPostCommentsQuery($id: ID!) {
    groupPost(id: $id) {
      id
      comments {
        id
        ${postCommentAttributes}
      }
      permissions {
        editable
      }
      group {
        id
        permissions {
          editable
        }
      }
    }
  }
`;

export const createGroupCommentMutation = gql`
  mutation CreateGroupCommentMutation($groupPostId: ID!, $data: GroupCommentInput!) {
    createGroupComment(groupPostId: $groupPostId, data: $data) {
      id
      ${postCommentAttributes}
      groupPost {
        id
        membership {
          id
          notificationPreference
        }
      }
    }
  }
`;

export const removeGroupCommentMutation = gql`
  mutation RemoveGroupCommentMutation($id: ID!) {
    removeGroupComment(id: $id) {
      id
    }
  }
`;

export const updateGroupPostMemberNotificationPreferenceMutation = gql`
  mutation UpdateGroupPostMemberNotificationPreferenceMutation(
    $groupPostId: ID!,
    $preference: NotificationPreference!
  ) {
    updateGroupPostMemberNotificationPreference(
      groupPostId: $groupPostId,
      preference: $preference
    ) {
      id
      notificationPreference
    }
  }
`;

export const editGroupPostQuery = gql`
  query EditGroupPostQuery($id: ID!) {
    groupPost(id: $id) {
      id
      ${postAttributes}
      group {
        ${groupPageAttributes}
      }
    }
  }
`;

export const updateGroupPostMutation = gql`
  mutation UpdateGroupPostMutation($id: ID!, $data: GroupPostInput!) {
    updateGroupPost(id: $id, data: $data) {
      id
      ${postAttributes}
      group {
        id
        ${groupBasicAttributes}
      }
    }
  }
`;

export const updateGroupImageMutation = gql`
  mutation UpdateGroupImageMutation($id: ID!, $image: Upload!) {
    updateGroupImage(id: $id, image: $image) {
      id
      image {
        filename
      }
    }
  }
`;

const groupMembershipAttributes = `
  eventNotificationPreference
  postNotificationPreference
  emailVisibility
  phoneNumberVisibility
  discoverable
`;

export const groupMembershipQuery = gql`
  query GroupMembershipQuery($slug: String!) {
    group(slug: $slug) {
      ${groupPageAttributes}
      membership {
        id
        role
        ${groupMembershipAttributes}
      }
    }
  }
`;

export const updateGroupMembershipMutation = gql`
  mutation UpdateGroupMembershipMutation($groupId: ID!, $data: GroupMemberInput!) {
    updateGroupMembership(groupId: $groupId, data: $data) {
      id
      ${groupMembershipAttributes}
    }
  }
`;

const groupsIndexAttributes = `
  id
  slug
  name
  summary
  public
  image {
    filename
  }
  upcomingEventsCount
  instagramUsername
  websiteUrl
  found
  permissions {
    createEvent
    createPost
    uploadImage
    editable
  }
`;

export const cityGroupsQuery = gql`
  query CityGroupsQuery($slug: String!) {
    city(slug: $slug) {
      id
      slug
      name
      groups {
        ${groupsIndexAttributes}
      }
    }
  }
`;

export const myGroupsQuery = gql`
  query MyGroupsQuery {
    me {
      id
      groups {
        ${groupsIndexAttributes}
        upcomingEventsCount
        recentPostsCount
        permissions {
          createEventWithoutApproval
        }
      }
    }
  }
`;
