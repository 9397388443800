import { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/footer';
import SignupBanner from './signup-banner';
import SupportBanner from './support-banner';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: theme.spacing(0, 1.5, 2, 1.5),
      backgroundColor: '#fff',
      overflow: 'hidden',
    },
  },
  main: {
    padding: theme.spacing(1, 3, 3, 3),
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  fullWidth: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  supportBanner: {
    margin: theme.spacing(3, 0, 0, 0),
  },
}));

const Main = (props) => {
  const { children, fullWidth, signupBanner } = props;
  const classes = useStyles();
  return (
    <Fragment>
      {signupBanner &&  <SignupBanner />}

      <Container
        maxWidth={fullWidth ? null : 'md'}
        component="div"
        className={clsx(classes.container, { [classes.fullWidth]: fullWidth })}
      >
        <main className={classes.main}>
          {children}
        </main>

        <SupportBanner className={classes.supportBanner} />

        <Footer />
      </Container>
    </Fragment>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  signupBanner: PropTypes.bool,
};

Main.defaultProps = {
  fullWidth: false,
  signupBanner: false,
};

export default Main;
