import gql from 'graphql-tag';
import { userFollowAttributes } from './types/user-follow';
import { groupMemberAttributes } from './types/group-member';

const meAttributes = `
  id
  givenName
  familyName
  displayName
  email
  phoneNumber
  phoneNumberDisplay: phoneNumber(format: NATIONAL)
  avatar {
    small {
      url
    }
  }
  username
  primaryCity {
    id
    slug
    name
  }
  groupMembers {
    ${groupMemberAttributes}
  }
  userFollows {
    ${userFollowAttributes}
  }
  calendarToken
  unreadNotificationsCount: notificationsCount(filter: UNREAD)
  defaultLicense
`;

export const meQuery = gql`
  query MeQuery {
    me {
      ${meAttributes}
    }
  }
`;

export const nearestCityQuery = gql`
  query NearestCityQuery($coordinates: CoordinatesInput) {
    nearestCity(coordinates: $coordinates) {
      id
      slug
      name
    }
  }
`;
