import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import YellowPaper from './yellow-paper';

const useStyles = makeStyles((theme) => ({
  message: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1, 2),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  patreonButton: {
    '-webkit-box-align': 'center',
    alignItems: 'center',
    backfaceVisibility: 'hidden',
    backgroundColor: 'rgb(255, 66, 77)',
    borderRadius: 9999,
    border: '1px solid rgb(255, 66, 77)',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'inline-flex',
    fontWeight: 500,
    height: 'unset',
    '-webkit-box-pack': 'center',
    justifyContent: 'center',
    padding: '0.46875rem 1rem',
    position: 'relative',
    pointerEvents: 'unset',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'none',
    transition: 'all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s',
    userSelect: 'none',
    whiteSpace: 'unset',
    width: '100%',
    color: 'rgb(255, 255, 255) !important',
    fontSize: '0.875rem !important',
  },
  patreonInner: {
    '-webkit-box-align': 'center',
    alignItems: 'center',
    display: 'flex',
    '-webkit-box-pack': 'center',
    justifyContent: 'center',
    visibility: 'visible',
  },
  patreonIcon: {
    display: 'inline-block',
    verticalAlign: 'unset',
    '& > svg': {
      display: 'block',
      width: '1rem',
      height: '1rem',
      overflow: 'hidden',
    },
  },
  patreonDot: {
    height: 1,
    width: '0.5rem',
  },
}));

const SupportBanner = ({ className }) => {
  const classes = useStyles();

  return (
    <YellowPaper className={className}>
      <div className={classes.message}>
        Everyday Rides is a free service built for the community with donated time. Please consider supporting the site. ❤️🫶💖
      </div>
      <div className={classes.buttons}>
        <div>
          <form action="https://www.paypal.com/donate" method="post" target="_blank">
            <input type="hidden" name="hosted_button_id" value="MCW62PKB3CL9E" />
            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form>
        </div>
        <div>
          <a className={classes.patreonButton} color="primary" tabIndex="0" type="button" href="https://www.patreon.com/bePatron?u=28468188" role="button" target="_blank" rel="noopener noreferrer">
            <div className={classes.patreonInner}>
              <div className={classes.patreonIcon}>
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.82 2.398c-3.97 0-7.2 3.234-7.2 7.208 0 3.963 3.23 7.187 7.2 7.187 3.959 0 7.18-3.224 7.18-7.187 0-3.974-3.221-7.208-7.18-7.208zM2 21.598h3.516v-19.2H2v19.2z" fillRule="evenodd" clipRule="evenodd"></path></svg>
              </div>
              <div className={classes.patreonDot}></div>
              Become a patron
            </div>
          </a>
        </div>
      </div>
    </YellowPaper>
  );
};

SupportBanner.propTypes = {
  className: PropTypes.string,
};

export default SupportBanner;
