import { useState, useRef, useMemo, useCallback, useEffect, Fragment } from 'react';
import Router from 'next/router';
import { useQuery } from '@apollo/client';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import Link from 'next/link';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import EventIcon from '@material-ui/icons/Event';
import MessageIcon from '@material-ui/icons/Message';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import MaterialLink from './material-link';
import { myGroupsQuery } from '../apollo/groups';
import { useAppContext } from '../contexts/app';
import { buildImageUrl } from '../utils/images';

const useStyles = makeStyles((theme) => ({
  menuLink: {
    textDecoration: 'none',
    color: '#000',
  },
  link: {
    margin: theme.spacing(1, 1.5),
    display: 'flex',
    alignItems: 'center',
  },
  dropdownButton: {
    margin: theme.spacing(0.25, 0.5),
    paddingRight: theme.spacing(0.25),
  },
  browseButton: {
    display: 'flex',
    width: '100%',
  },
  upcomingChip: {
    marginLeft: theme.spacing(1),
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  buttonChip: {
    marginLeft: theme.spacing(1),
    border: 'none',
    cursor: 'pointer',
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      padding: 0,
    },
  },
  chipIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 20,
  },
  eventsIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    fontSize: 20,
  },
  postsIcon: {
    marginLeft: theme.spacing(0.5),
    fontSize: 20,
  },
  avatar: {
    height: '1em',
    width: '1em',
    marginRight: theme.spacing(0.75),
  },
}));

const HeaderGroups = (props) => {
  const classes = useStyles();
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const { isLoggedIn } = useAppContext();

  const { data: groupsData, loading: groupsLoading } = useQuery(myGroupsQuery, {
    skip: !isLoggedIn,
  });
  const groups = useMemo(() => {
    if (!groupsData) {
      return [];
    }
    return _sortBy(_get(groupsData, 'me.groups', []), (group) => [
      (group.upcomingEventsCount || group.recentPostsCount) ? 0 : 1,
      group.name,
    ]);
  }, [groupsData]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleClose);

    return () => {
      Router.events.off('routeChangeStart', handleClose);
    };
  }, []);

  if (!isLoggedIn) {
    return (
      <MaterialLink
        href="/groups"
        variant="button"
        color="textPrimary"
        className={classes.link}
      >
        Groups
      </MaterialLink>
    )
  }

  return (
    <Fragment>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        ref={buttonRef}
        className={classes.dropdownButton}
        {...props}
      >
        Groups
        <ArrowIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={buttonRef.current}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {groupsLoading ? (
          <MenuItem>Loading...</MenuItem>
        ) : _map(groups, (group) => {
          return (
            <Link
              className={classes.menuLink}
              href="/groups/[slug]"
              as={`/groups/${group.slug}`}
              key={group.id}
              legacyBehavior
            >
              <MenuItem component="a" href={`/groups/${group.slug}`}>
                {group.image && (
                  <Avatar
                    alt={group.name}
                    src={buildImageUrl(group.image, '20x20')}
                    className={classes.avatar}
                  />
                )}
                <div>{group.name}</div>
                {group.upcomingEventsCount > 0 && (
                  <Chip
                    size="small"
                    label={(
                      <Fragment>
                        <EventIcon className={classes.chipIcon} /> {group.upcomingEventsCount} upcoming
                      </Fragment>
                    )}
                    variant="outlined"
                    color="primary"
                    className={classes.upcomingChip}
                  />
                )}
                {group.recentPostsCount > 0 && (
                  <Chip
                    size="small"
                    label={(
                      <Fragment>
                        <MessageIcon className={classes.chipIcon} /> {group.recentPostsCount} recent
                      </Fragment>
                    )}
                    variant="outlined"
                    className={classes.upcomingChip}
                  />
                )}
              </MenuItem>
            </Link>
          );
        })}
        <Link href="/groups" legacyBehavior>
          <MenuItem>
            <Button
              href="/groups" 
              variant="outlined"
              className={classes.browseButton}
            >
              Browse All Groups
            </Button>
          </MenuItem>
        </Link>
        <Link href="/groups/new" legacyBehavior>
          <MenuItem>
            <Button
              href="/groups/new" 
              variant="contained"
              color="primary"
              className={classes.browseButton}
            >
              Create Group
            </Button>
          </MenuItem>
        </Link>
      </Menu>
    </Fragment>
  );
};

export default HeaderGroups;
