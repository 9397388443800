import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-unfetch';
import config from '../config';

let apolloClient = null;
// const SERVER_PORT = parseInt(process.env.PORT, 10) || 3000;

const create = (initialState, reqCookie) => {
  const isBrowser = typeof window !== 'undefined';
  const httpLink = createUploadLink({
    uri: config().universal.graphqlUri,
    // uri: !isBrowser ? `http://127.0.0.1:${SERVER_PORT}/graphql` : config().universal.graphqlUri,
    fetch: !isBrowser ? (url, init) => {
      return fetch(url, {
        ...init,
        headers: {
          ...init.headers,
          Cookie: reqCookie
        }
      });
    } : undefined,
    credentials: 'same-origin'
  });
  // Docs on field policies for pagination
  // https://www.apollographql.com/docs/react/pagination/core-api#defining-a-field-policy
  // const typePolicies = {
  //   User: {
  //     fields: {
  //       notifications: {
  //         keyArgs: ['filter'],
  //         read(name) {
  //           console.log(name);
  //           return name;
  //         },
  //         merge(existing = [], incoming) {
  //           console.log('merge');
  //           return [...existing, ...incoming];
  //         },
  //       },
  //     },
  //   },
  // };
  const possibleTypes = {
    NotificationRecord: [
      'Event',
      'EventComment',
      'EventMember',
      'EventInvite',
      'EventSeries',
      'GroupMember',
      'GroupPost',
      'GroupComment',
      'GroupInvite',
      'UserFollow',
    ],
    EventOwner: ['User', 'Group'],
  };
  const client = new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
    link: httpLink,
    cache: new InMemoryCache({
      possibleTypes,
    }).restore(initialState || {})
  });
  return client;
};

export default (initialState = {}, reqCookie) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return create(initialState, reqCookie);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, reqCookie);
  }

  return apolloClient;
};
