import { Fragment, useState, useContext, useCallback, useEffect } from 'react';
import Link from 'next/link';
import Router from 'next/router';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from '../contexts/app';

const useStyles = makeStyles((theme) => ({
  notificationsIcon: {
    color: '#777',
    margin: theme.spacing(0, 1),
  },
  loginButton: {
    marginLeft: theme.spacing(1),
    '& > *': {
      display: 'inline-block',
    },
  },
  desktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const HeaderAuth = () => {
  const {
    isLoggedIn,
    authLoading,
    loggedInUser,
    login,
    logout,
  } = useContext(AppContext);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const classes = useStyles();

  const openMenu = useCallback((event) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  useEffect(() => {
    Router.events.on('routeChangeStart', closeMenu);

    return () => {
      Router.events.off('routeChangeStart', closeMenu);
    };
  }, []);

  if (isLoggedIn) {
    return (
      <Fragment>
        <Hidden smDown implementation="css">
          <Link href="/notifications" legacyBehavior>
            <Badge
              component="a"
              href="/notifications"
              variant="dot"
              invisible={!loggedInUser || !loggedInUser.unreadNotificationsCount}
              color="primary"
              overlap="circular"
              className={classes.notificationsIcon}
            >
              <NotificationsIcon />
            </Badge>
          </Link>
        </Hidden>
        <IconButton
          aria-label="Account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={openMenu}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(menuAnchorEl)}
          onClose={closeMenu}
        >
          <Link
            href="/people/[username]"
            as={`/people/${loggedInUser.username}`}
            legacyBehavior
          >
            <MenuItem
              component="a"
              href={`/people/${loggedInUser.username}`}
            >
              Profile
            </MenuItem>
          </Link>
          <Link href="/account" legacyBehavior>
            <MenuItem
              component="a"
              href="/account"
            >
              Account
            </MenuItem>
          </Link>
          <Link href="/calendar?filter=rsvp" legacyBehavior>
            <MenuItem
              component="a"
              href="/calendar?filter=rsvp"
            >
              Your RSVPs
            </MenuItem>
          </Link>
          <Link href="/calendar?filter=managed" legacyBehavior>
            <MenuItem
              component="a"
              href="/calendar?filter=managed"
            >
              Your Hosting
            </MenuItem>
          </Link>
          <Link href="/calendar?filter=drafts" legacyBehavior>
            <MenuItem
              component="a"
              href="/calendar?filter=drafts"
            >
              Your Drafts
            </MenuItem>
          </Link>
          <Link href="/event-series" legacyBehavior>
            <MenuItem
              component="a"
              href="/event-series"
            >
              Your Event Series
            </MenuItem>
          </Link>
          <Link href="/image-uploads" legacyBehavior>
            <MenuItem
              component="a"
              href="/image-uploads"
            >
              Your Photos
            </MenuItem>
          </Link>
          <Link href="/groups" legacyBehavior>
            <MenuItem
              component="a"
              href="/groups"
            >
              Your Groups
            </MenuItem>
          </Link>
          <Link href="/people/following" legacyBehavior>
            <MenuItem
              component="a"
              href="/people/following"
            >
              Your Following
            </MenuItem>
          </Link>
          <Link href="/calendar/subscribe" legacyBehavior>
            <MenuItem
              component="a"
              href="/calendar/subscribe"
            >
              Subscribe to Calendar
            </MenuItem>
          </Link>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              logout(Router.asPath);
            }}
          >
            Log Out
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }

  return (
    <Button
      href="/login"
      variant="outlined"
      onClick={(e) => {
        e.preventDefault();
        login(Router.asPath);
      }}
      disabled={authLoading}
      className={classes.loginButton}
    >
      {authLoading ? 'Loading...' : (
        <Fragment>
          <span className={classes.desktopOnly}>
            Sign Up or
            {' '}
          </span>
          Log In
        </Fragment>
      )}
    </Button>
  );
};

export default HeaderAuth;
