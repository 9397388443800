export const groupBasicAttributes = `
  name
  slug
  public
  found
  image {
    filename
  }
  verified
  permissions {
    accessible
    createEvent
    createPost
    uploadImage
    editable
    joined
  }
`;
