import { useMemo, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import Link from 'next/link';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EventIcon from '@material-ui/icons/Event';
import MessageIcon from '@material-ui/icons/Message';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { myGroupsQuery } from '../apollo/groups';
import { useAppContext } from '../contexts/app';
import { buildImageUrl } from '../utils/images';

const useStyles = makeStyles((theme) => ({
  browseButton: {
    display: 'flex',
    width: '100%',
  },
  chips: {
    marginTop: 2,
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(0.5),
    },
  },
  upcomingChip: {
    marginTop: theme.spacing(0.5),
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  chipIcon: {
    marginRight: theme.spacing(0.5),
    fontSize: 20,
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: '1em',
    width: '1em',
    marginRight: theme.spacing(0.75),
  },
}));

const HeaderGroupsMobile = () => {
  const classes = useStyles();
  const { isLoggedIn } = useAppContext();

  const { data: groupsData, loading: groupsLoading } = useQuery(myGroupsQuery, {
    skip: !isLoggedIn,
  });
  const groups = useMemo(() => {
    if (!groupsData) {
      return [];
    }
    return _sortBy(_get(groupsData, 'me.groups', []), (group) => [
      (group.upcomingEventsCount || group.recentPostsCount) ? 0 : 1,
      group.name,
    ]);
  }, [groupsData]);

  return (
    <List>
      {groupsLoading ? (
        <ListItem>Loading...</ListItem>
      ) : _map(groups, (group) => {
        return (
          <Link
            className={classes.menuLink}
            href="/groups/[slug]"
            as={`/groups/${group.slug}`}
            key={group.id}
            legacyBehavior
          >
            <ListItem button component="a" href={`/groups/${group.slug}`}>
              <ListItemText>
                <div className={classes.nameWrapper}>
                  {group.image && (
                    <Avatar
                      alt={group.name}
                      src={buildImageUrl(group.image, '20x20')}
                      className={classes.avatar}
                    />
                  )}
                  {group.name}
                </div>
                {(group.upcomingEventsCount > 0 || group.recentPostsCount > 0) && (
                  <div className={classes.chips}>
                    {group.upcomingEventsCount > 0 && (
                      <Chip
                        size="small"
                        label={(
                          <Fragment>
                            <EventIcon className={classes.chipIcon} /> {group.upcomingEventsCount} upcoming
                          </Fragment>
                        )}
                        variant="outlined"
                        color="primary"
                        className={classes.upcomingChip}
                      />
                    )}
                    {group.recentPostsCount > 0 && (
                      <Chip
                        size="small"
                        label={(
                          <Fragment>
                            <MessageIcon className={classes.chipIcon} /> {group.recentPostsCount} recent
                          </Fragment>
                        )}
                        variant="outlined"
                        className={classes.upcomingChip}
                      />
                    )}
                  </div>
                )}
              </ListItemText>
            </ListItem>
          </Link>
        );
      })}
      <Link href="/groups" legacyBehavior>
        <ListItem button component="a" href="/groups">
          <ListItemText>
            <Button
              href="/groups" 
              variant="outlined"
              className={classes.browseButton}
            >
              Browse All Groups
            </Button>
          </ListItemText>
        </ListItem>
      </Link>
      <Link href="/groups/new" legacyBehavior>
        <ListItem button component="a" href="/groups/new">
          <ListItemText>
            <Button
              href="/groups/new" 
              variant="outlined"
              color="primary"
              className={classes.browseButton}
            >
              Create Group
            </Button>
          </ListItemText>
        </ListItem>
      </Link>
    </List>
  );
};

export default HeaderGroupsMobile;
