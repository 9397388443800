import { stringify } from 'qs';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import config from '../config';

export const stringifyFilters = (filters) => {
  return stringify(filters, {
    arrayFormat: 'comma',
    encode: false,
  });
};

export const filterableRoute = (baseRoute, filters) => {
  let route = `${baseRoute}`;

  // filter out null and undefined filters
  const validFilters = Object.assign({}, filters);
  forEach(validFilters, (v, k) => {
    if (isNil(v)) {
      delete validFilters[k];
    }
  });

  if (Object.keys(validFilters).length) {
    route += `?${stringifyFilters(validFilters)}`;
  }

  return route;
};

export const addAnchor = (path, anchor) => {
  return `${path}#${anchor}`;
};

export const url = (path) => `${config().universal.baseUri}${path}`;
