import { Component } from 'react'
import PropTypes from 'prop-types';
import initApollo from './init-apollo';
import get from 'lodash/get';
import { getDataFromTree } from '@apollo/client/react/ssr';

const WithApolloClient = App => {
  class Apollo extends Component {
    static async getInitialProps(ctx) {
      const { AppTree } = ctx;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data,
      const reqCookie = get(ctx, 'ctx.req.headers.cookie');
      const apollo = initApollo({}, reqCookie);
      if (typeof window === 'undefined') {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <AppTree
              {...appProps}
              reqCookie={reqCookie}
              apolloClient={apollo}
            />
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          console.error('Error while running `getDataFromTree`', error);
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        // Head.rewind();
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
        reqCookie
      };
    }

    constructor(props) {
      super(props);
      this.apolloClient = initApollo(props.apolloState, props.reqCookie);
    }

    render() {
      return <App apolloClient={this.apolloClient} {...this.props} />;
    }
  }
  Apollo.propTypes = {
    apolloState: PropTypes.object,
    reqCookie: PropTypes.any
  };
  Apollo.displayName = 'withApollo(App)';
  return Apollo;
};

export default WithApolloClient;
