import { Fragment, useContext } from 'react';
import Link from 'next/link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import MaterialLink from './material-link';
import HeaderResources from './header-resources';
import AppContext from '../contexts/app';
import HeaderAuth from './header-auth';
import HeaderGroups from './header-groups';

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
  },
  toolbar: {
    flexWrap: 'wrap',
    minHeight: 50,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  toolbarTitle: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'baseline',
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  toolbarTitleLink: {
    textDecoration: 'none',
    color: '#222',
    fontFamily: '\'Sriracha\', sans-serif',
  },
  toolbarTitleCity: {
    textDecoration: 'none',
    color: '#509156',
    fontWeight: 'normal',
    fontFamily: '\'Sriracha\', sans-serif',
  },
  link: {
    margin: theme.spacing(1, 1.5),
    display: 'flex',
    alignItems: 'center',
  },
  mobileDrawerButton: {
  },
  homeIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
    margin: theme.spacing(0, 1),
  },
  cityIcon: {
    color: '#777',
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    },
    '@media (max-width:360px)': {
      display: 'none'
    }
  },
  addIcon: {
    marginRight: theme.spacing(0.5),
  },
  mobileOnly: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  desktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  headerLinks: {
    display: 'flex',
    alignItems: 'center',
  },
  headerDesktopLinks: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
}));

const Header = () => {
  const {
    setMobileDrawerOpen,
    mobileDrawerOpen,
    city,
  } = useContext(AppContext);
  const classes = useStyles();

  return (
    <AppBar position="static" elevation={0} className={classes.appBar}>
      <Toolbar component="nav" className={classes.toolbar}>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setMobileDrawerOpen(!mobileDrawerOpen)}
            edge="start"
            className={classes.mobileDrawerButton}
          >
            <MenuIcon htmlColor="#000" />
          </IconButton>
        </Hidden>
        <Typography className={classes.toolbarTitle}>
          <Link href="/calendar" className={classes.toolbarTitleLink}>
            Everyday Rides{city && (
              <Fragment>
                {' '}
                <span className={classes.toolbarTitleCity}>
                  {city.name}
                </span>
              </Fragment>
            )}
          </Link>
        </Typography>
        <div className={classes.headerLinks}>
          <Hidden smDown implementation="css" className={classes.headerDesktopLinks}>
            <MaterialLink
              href="/events/new"
              variant="button"
              color="textPrimary"
              className={classes.link}
              title="Add Event"
            >
              <AddIcon className={classes.addIcon} /> Add Event
            </MaterialLink>
            <MaterialLink
              href="/calendar"
              variant="button"
              color="textPrimary"
              className={classes.link}
            >
              Calendar
            </MaterialLink>
            <HeaderGroups />
            <HeaderResources
              className={classes.link}
            />
          </Hidden>
          <HeaderAuth />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
