export const imageUploadAttributes = `
  id
  image {
    filename
    width
    height
  }
  status
  license
  anonymous
  createdByUser {
    id
    username
    displayName
    avatar {
      small {
        url
      }
    }
  }
  permissions {
    editable
  }
  createdAt
`;
