import React from 'react';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import MLink from '@material-ui/core/Link';
import Add from '@material-ui/icons/Add';
import MaterialLink from './material-link';
import { momentParse } from '../utils/time';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  divider: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  linkGroup: {
    display: 'flex',
    position: 'relative',
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  linkGroupMain: {
    flexGrow: 1,
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },
  navLinks: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: theme.spacing(1, 0)
    },
    '& > li': {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(0, 1.5),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 1)
      },
      '&:first-of-type': {
        marginLeft: 0
      },
      '&:last-of-type': {
        marginRight: 0
      }
    }
  },
  socialLinks: {
    position: 'absolute',
    listStyleType: 'none',
    top: 0,
    right: 0,
    margin: 0,
    padding: 0,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      justifyContent: 'center',
      margin: theme.spacing(2, 0),
    },
    '& li': {
      margin: theme.spacing(0, 1),
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  socialIcon: {
    opacity: 0.6,
  },
  copyright: {
    margin: theme.spacing(1, 0, 1, 0),
    color: '#777',
  },
  linkWithIcon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  linkIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.wrapper}>
      <Divider className={classes.divider} />

      <nav className={classes.linkGroup}>
        <div className={classes.linkGroupMain}>
          <ul className={classes.navLinks}>
            <li>
              <MaterialLink href="/about">
                About
              </MaterialLink>
            </li>
            <li>
              <MaterialLink href="/features">
                Features
              </MaterialLink>
            </li>
            <li>
              <MaterialLink href="/cities">
                Cities
              </MaterialLink>
            </li>
            <li>
              <MaterialLink href="/terms">
                Terms
              </MaterialLink>
            </li>
            <li>
              <MaterialLink href="/privacy">
                Privacy
              </MaterialLink>
            </li>
            <li>
              <MaterialLink href="/contact">
                Contact
              </MaterialLink>
            </li>
          </ul>
          <ul className={classes.navLinks}>
            <li>
              <MaterialLink
                href="/events/new"
                className={classes.linkWithIcon}
              >
                <Add className={classes.linkIcon} /> Add Event
              </MaterialLink>
            </li>
            <li>
              <MaterialLink
                href="/calendar"
              >
                Calendar
              </MaterialLink>
            </li>
            <li>
              <MaterialLink
                href="/groups"
              >
                Groups
              </MaterialLink>
            </li>
            <li>
              <MLink
                href="https://blog.everydayrides.com"
              >
                Blog
              </MLink>
            </li>
          </ul>
          <ul className={classes.navLinks}>
            <li>
              <MLink
                href="https://blog.everydayrides.com/bike-based-activity-ideas-in-seattle/"
              >
                Bike Ride Ideas
              </MLink>
            </li>
            <li>
              <MaterialLink href="/group-ride-guide">
                Group Ride Guide
              </MaterialLink>
            </li>
            {/* <li>
              <MaterialLink href="/event-listing-guide">
                Event Listing Guide
              </MaterialLink>
            </li> */}
            <li>
              <MLink href="https://wabikepacking.com">
                WA Bikepacking
              </MLink>
            </li>
          </ul>
        </div>
        <ul className={classes.socialLinks}>
          <li>
            <MLink
              href="https://www.instagram.com/everydayridescom"
              title="Everyday Rides on Instagram"
            >
              <img width="28" height="28" src="/static/instagram-brands.svg" className={classes.socialIcon} />
            </MLink>
          </li>
          <li>
            <MLink
              href="https://facebook.com/everydayridescom"
              title="Everyday Rides on Facebook"
            >
              <img width="28" height="28" src="/static/facebook-square-brands.svg" className={classes.socialIcon} />
            </MLink>
          </li>
          <li>
            <MLink
              href="https://twitter.com/everyday_rides"
              title="Everyday Rides on Twitter"
            >
              <img width="28" height="28" src="/static/twitter-brands.svg" className={classes.socialIcon} />
            </MLink>
          </li>
        </ul>
      </nav>

      <p className={classes.copyright}>
        Copyright &copy; {momentParse().format('YYYY')}
      </p>
    </footer>
  );
};

export default Footer;
