import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Router from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useAppContext } from '../contexts/app';
import HeaderGroupsMobile from '../components/header-groups-mobile';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  withIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  header: {
    marginLeft: theme.spacing(2),
    marginBottom: 0,
  },
}));

const MobileDrawer = () => {
  const {
    mobileDrawerOpen: open,
    setMobileDrawerOpen: setOpen,
    loggedInUser,
    logout,
    login,
  } = useAppContext();
  const classes = useStyles();

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <nav
        className={classes.list}
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <List>
          <Link href="/events/new" legacyBehavior>
            <ListItem button component="a" title="Add Event" href="/events/new">
              <ListItemText
                primaryTypographyProps={{
                  className: classes.withIcon,
                }}
              >
                <AddIcon className={classes.icon} /> 
                Add Event
              </ListItemText>
            </ListItem>
          </Link>
          <Link href="/calendar" legacyBehavior>
            <ListItem button component="a" href="/calendar">
              <ListItemText primary="Calendar" />
            </ListItem>
          </Link>
          {!loggedInUser && (
            <Link href="/groups" legacyBehavior>
              <ListItem button component="a" href="/groups">
                <ListItemText primary="Groups" />
              </ListItem>
            </Link>
          )}
        </List>
        {loggedInUser && (
          <Fragment>
            <Divider />
            <Typography variant="h3" className={classes.header}>Groups</Typography>
            <HeaderGroupsMobile />
          </Fragment>
        )}
        <Divider />
        <Typography variant="h3" className={classes.header}>Resources</Typography>
        <List>
          <ListItem button component="a" href="https://blog.everydayrides.com">
            <ListItemText primary="Blog" />
          </ListItem>
          <ListItem button component="a" href="https://blog.everydayrides.com/bike-based-activity-ideas-in-seattle/">
            <ListItemText primary="Bike Ride Ideas" />
          </ListItem>
          <Link href="/group-ride-guide" legacyBehavior>
            <ListItem button component="a" href="/group-ride-guide">
              <ListItemText>
                Group Ride Guide
              </ListItemText>
            </ListItem>
          </Link>
          {/* <Link href="/event-listing-guide">
            <ListItem button component="a" href="/event-listing-guide">
              <ListItemText>
                Event Listing Guide
              </ListItemText>
            </ListItem>
          </Link> */}
          <ListItem button component="a" href="https://wabikepacking.com">
            <ListItemText primary="WA Bikepacking" />
          </ListItem>
        </List>
        <Divider />
        <Typography variant="h3" className={classes.header}>Account</Typography>
        {loggedInUser ? (
          <List>
            <Link href="/notifications" legacyBehavior>
              <ListItem button component="a" href="/notifications">
                <ListItemText>
                  Notifications
                  {loggedInUser.unreadNotificationsCount > 0 && ' (unread)'}
                </ListItemText>
              </ListItem>
            </Link>
            <Link
              href="/people/[username]"
              as={`/people/${loggedInUser.username}`}
              legacyBehavior
            >
              <ListItem button component="a" href={`/people/${loggedInUser.username}`}>
                <ListItemText>
                  Profile
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/account" legacyBehavior>
              <ListItem button component="a" href="/account">
                <ListItemText>
                  Account
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/calendar?filter=rsvp" legacyBehavior>
              <ListItem button component="a" href="/calendar?filter=rsvp">
                <ListItemText>
                  Your RSVPs
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/calendar?filter=managed" legacyBehavior>
              <ListItem button component="a" href="/calendar?filter=managed">
                <ListItemText>
                  Your Hosting
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/calendar?filter=drafts" legacyBehavior>
              <ListItem button component="a" href="/calendar?filter=drafts">
                <ListItemText>
                  Your Drafts
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/event-series" legacyBehavior>
              <ListItem button component="a" href="/event-series">
                <ListItemText>
                  Your Event Series
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/image-uploads" legacyBehavior>
              <ListItem button component="a" href="/image-uploads">
                <ListItemText>
                  Your Image Uploads
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/groups" legacyBehavior>
              <ListItem button component="a" href="/groups">
                <ListItemText>
                  Your Groups
                </ListItemText>
              </ListItem>
            </Link>
            <Link href="/following" legacyBehavior>
              <ListItem button component="a" href="/following">
                <ListItemText>
                  Your Following
                </ListItemText>
              </ListItem>
            </Link>
            <ListItem button onClick={() => logout(Router.asPath)}>
              <ListItemText>
                Log Out
              </ListItemText>
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem button onClick={() => login(Router.asPath)}>
              <ListItemText>
                Sign Up or Log In
              </ListItemText>
            </ListItem>
          </List>
        )}
      </nav>
    </Drawer>
  );
};

MobileDrawer.propTypes = {
  mobileDrawerOpen: PropTypes.bool,
  setMobileDrawerOpen: PropTypes.func,
};

export default MobileDrawer;
