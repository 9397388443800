import { useState, useRef, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dropdownButton: {
    margin: theme.spacing(0.25, 0.5),
    paddingRight: theme.spacing(0.25),
    color: theme.palette.text.primary,
  },
  menuLink: {
    textDecoration: 'none',
    color: '#000',
  },
}));

const HeaderResources = ({ className, ...rest }) => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleClose);

    return () => {
      Router.events.off('routeChangeStart', handleClose);
    };
  }, []);

  return (
    <Fragment>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpen}
        ref={buttonRef}
        className={clsx(className, classes.dropdownButton)}
        {...rest}
      >
        Resources <ArrowIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={buttonRef.current}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem component="a" href="https://blog.everydayrides.com">
          Blog
        </MenuItem>
        <MenuItem component="a" href="https://blog.everydayrides.com/bike-based-activity-ideas-in-seattle/">
          Bike Ride Ideas
        </MenuItem>
        <Link className={classes.menuLink} href="/group-ride-guide" legacyBehavior>
          <MenuItem component="a" href="/group-ride-guide">
            Group Ride Guide
          </MenuItem>
        </Link>
        {/* <Link className={classes.menuLink} href="/event-listing-guide" legacyBehavior>
          <MenuItem component="a" href="/event-listing-guide">
            Event Listing Guide
          </MenuItem>
        </Link> */}
        <MenuItem component="a" href="https://wabikepacking.com">
          WA Bikepacking
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

HeaderResources.propTypes = {
  className: PropTypes.string,
};

export default HeaderResources;
