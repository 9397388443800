import moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import config from '../config';

export const momentParse = (rawDate, timezone = config().universal.timezone) => {
  let m;
  if (rawDate) {
    m = moment.utc(rawDate);
  } else {
    m = moment();
  }
  return m.tz(timezone);
};

export const dateAndTimeRange = (startAt, endAt) => {
  const dateFormat = 'dddd, MMMM D, YYYY';
  const timeFormat = 'h:mm a';
  const dateAndTimeFormat = `${dateFormat} ${timeFormat}`;
  const start = startAt.format(dateAndTimeFormat);

  // support events without end time
  if (!endAt || endAt.isSame(startAt)) {
    return start;
  }

  if (startAt.isSame(endAt, 'day')) {
    return `${start} – ${endAt.format(timeFormat)}`;
  }

  return `${start} – ${endAt.format(dateAndTimeFormat)}`;
};

export const eventDateAndTimeRange = (event) => {
  const { startAt, endAt, timezone } = event;
  return dateAndTimeRange(
    momentParse(startAt, timezone),
    endAt ? momentParse(endAt, timezone) : null,
  );
};

export const timeRange = (startAt, endAt, inlineDate = false) => {
  const start = startAt.format('h:mm a');

  // support events without end time
  if (!endAt || endAt.isSame(startAt)) {
    return start;
  }

  const end = endAt.format('h:mm a');
  if (startAt.isSame(endAt, 'day')) {
    return `${start} – ${end}`;
  }

  const dateFormat = 'MMMM D, YYYY';
  if (inlineDate) {
    return `${start} (${startAt.format(dateFormat)}) – ${end} (${endAt.format(dateFormat)})`;
  }
  return `${start} – ${end} (${startAt.format(dateFormat)} – ${endAt.format(dateFormat)})`;
};

export const dateRange = (startAt, endAt) => {
  if (!endAt || startAt.isSame(endAt, 'day')) {
    return startAt.format('MMMM D');
  }

  const start = `${startAt.format('MMMM D')}`;
  const end = endAt.format(startAt.isSame(endAt, 'month') ? 'D' : 'MMMM D');
  return `${start}-${end}`;
};

export const fromNow = (startAt, endAt) => {
  const now = moment();
  if (startAt.diff(now) > 0) {
    if (now.isSame(startAt, 'day')) {
      return 'Today';
    } else if (now.add(1, 'day').isSame(startAt, 'day')) {
      return 'Tomorrow';
    }
    return `${startAt.fromNow(true)} from now`;
  } else if (endAt.diff(now) < 0) {
    return 'Already Happened';
  }
  return 'Happening Now';
};

export const combineDateAndTime = (date, time) => {
  if (!date || !time) {
    return null;
  }
  const momentDate = momentParse(date);
  const momentTime = momentParse(time);
  if (!momentDate.isValid() || !momentTime.isValid()) {
    return null;
  }
  const combined = moment(
    `${momentDate.format('YYYY-MM-DD')} ${momentTime.format('HH:mm:ss')}`
  );
  return combined;
};
