export const userFollowAttributes = `
  id
  followingUser {
    id
    username
    displayName
  }
  status
  notificationPreference
`;
