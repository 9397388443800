import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}));

const YellowPaper = ({ className, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.paper, className)} {...rest}>
      {children}
    </Paper>
  );
};

YellowPaper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default YellowPaper;
