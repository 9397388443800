import PropTypes from 'prop-types';
import Link from 'next/link';
import MLink from '@material-ui/core/Link';

const MaterialLink = ({ href, as, children, ...props }) => (
  <Link href={href} as={as} legacyBehavior>
    <MLink href={as || href} {...props}>
      {children}
    </MLink>
  </Link>
);

MaterialLink.propTypes = {
  href: PropTypes.string,
  as: PropTypes.string,
  children: PropTypes.any
};

export default MaterialLink;
