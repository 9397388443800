import config from '../config';

export const imageSizes = {
  '20x20': {
    width: 20,
    height: 20
  },
  '20x20i': {
    maxWidth: 20,
    maxHeight: 20
  },
  '50x50': {
    width: 50,
    height: 50
  },
  '80x60i': {
    maxWidth: 80,
    maxHeight: 60
  },
  '80x80': {
    width: 80,
    height: 80
  },
  '133x100iwe': {
    maxWidth: 133,
    maxHeight: 100
  },
  '160x120iwe': {
    maxWidth: 160,
    maxHeight: 120
  },
  '200x150iwe': {
    maxWidth: 200,
    maxHeight: 150
  },
  // '200x200iwe': {
  //   maxWidth: 200,
  //   maxHeight: 200
  // },
  '300x300iwe': {
    maxWidth: 300,
    maxHeight: 300
  },
  '400x400iwe': {
    maxWidth: 400,
    maxHeight: 400
  }
};

export const buildImageUrl = (image, size) => {
  return `${config().universal.imagesBaseUrl}/${size}/${image.filename}`;
};
